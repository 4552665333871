import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { register } from "../../services/auth/auth";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
const Register = () => {
  const initialValue = {
    firstApplicant: "",
    additionalText: "Mr",
    parentHusband: "S",
    swdOf: "Mr",
    // msMrs: "",
    combinedValue: "",
    age: "",
    nationality: "",
    address: "",
    email: "",
    contactNumber: "",
    whatsappNumber: "",
    nomineeName: "",
    relationship: "",
    nomineeContactNumber: "",
    // signature: "",
    paymentDetails: "",
    paymentDate: "",
    utrNumber: "",
    paymentScreenshot: "",
    paidAmount: "",
    sponserId: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [errors, setErrors] = useState(initialValue);
  const [reffralCode, setReffralCode] = useState("");

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    let error = "";
    const regex = /^[6-9]\d{9}$/;

    const regexText = /^[A-Za-z\s]+$/;
    const regexImage = /\.(jpg|jpeg|png|gif)$/i;

    if (value === "") {
      error = "This field is required.";
    }

    // if (name === "paymentScreenshot" && e.target.files[0]) {
    //   const file = e.target.files[0];

    //   if (!regexImage.test(file?.name)) {
    //     error = "Please enter valid image format";

    //     setErrors({
    //       ...errors,
    //       [name]: error,
    //     });
    //   } else {
    //     setFormData({
    //       ...formData,
    //       [name]: file,
    //     });
    //   }
    //   setErrors({
    //     ...errors,
    //     [name]: error,
    //   });
    // }
    if (name === "paymentScreenshot" && e.target.files[0]) {
      const file = e.target.files[0];

      if (!regexImage.test(file?.name)) {
        error = "Please enter a valid image format";
      } else {
        setFormData({
          ...formData,
          [name]: file,
        });
      }

      setErrors({
        ...errors,
        [name]: error,
      });
    } else {
      if (name === "firstApplicant" && !regexText.test(value)) {
        error = "Enter valid format";
      }
      if (name === "combinedValue" && !regexText.test(value)) {
        error = "Enter valid format";
      }

      if (name === "age") {
        const age = Number(value);
        if (isNaN(age) || age < 0 || age > 100) {
          error = "Age must be a valid number with a maximum of 100.";
        }
      }

      if (name === "nationality" && !regexText.test(value)) {
        error = "Enter valid format";
      }
      if (name === "email" && value !== "") {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailPattern.test(value)) {
          error = "Please enter valid email";
        }
      }
      if (name === "contactNumber" && !regex.test(value)) {
        error = "Please enter valid mobile number.";
      }
      if (name === "whatsappNumber" && !regex.test(value)) {
        error = "Please enter valid mobile number.";
      }

      if (name === "nomineeName" && !regexText.test(value)) {
        error = "Enter valid format";
      }
      if (name === "relationship" && !regexText.test(value)) {
        error = "Enter valid format";
      }
      if (name === "nomineeContactNumber") {
        if (value && !regex.test(value)) {
          error = "Please enter valid mobile number.";
        }
      }

      // if (name === "paymentScreenshot" && !regexImage.test(files)) {
      //   error = "Please enter valid image format";

      //   setFormData({
      //     ...formData,
      //     [name]: files[0],
      //   });

      // if (name === "paymentScreenshot") {
      //   error = "";
      // }

      setFormData({
        ...formData,
        [name]: value,
      });
      console.log(error, "error");
      setErrors({
        ...errors,
        [name]: error,
      });
    }
  };

  const handleChangeRadio = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const params = useParams();
  const { id } = params;
  // original=====
  // const handleChangeForCombine = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "parentHusband") {

  //     const newValue = {
  //       ...formData,
  //       [name]: value,
  //       combinedValue: `${value} / ${formData.swdOf}`,
  //     };
  //     setFormData(newValue);
  //   } else if (name === "swdOf") {

  //     const newValue = {
  //       ...formData,
  //       [name]: value,
  //       combinedValue: `${formData.parentHusband} / ${value}`,
  //     };
  //     setFormData(newValue);
  //   } else if (name === "combinedValue") {

  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   }

  // };
  // ========= original =====

  useEffect(() => {
    if (id) {
      setReffralCode(id);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    const newErrors = {};
    let err = "This Field is required ";
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const regex = /^[6-9]\d{9}$/;

    const regexText = /^[A-Za-z\s]+$/;
    const regexTextSlash = /^[A-Za-z\s\/]+(\s\/)?[A-Za-z\s]+$/;
    const regexImage = /\.(jpg|jpeg|png|gif)$/i;

    const fieldsToValidate = [
      "firstApplicant",
      "combinedValue",
      "nationality",
      "address",
      "contactNumber",
      "whatsappNumber",
      "nomineeName",
      "relationship",
      "paymentDetails",
      // "paymentDate",
      "utrNumber",
      "paidAmount",
    ];

    for (const key of fieldsToValidate) {
      if (!formData[key]) {
        newErrors[key] = "This field is required.";
        isValid = false;
        break;
      }
      if (
        key === "firstApplicant" &&
        !regexText.test(formData.firstApplicant)
      ) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }

      if (key === "combinedValue") {
        if (!regexTextSlash.test(formData[key])) {
          newErrors[key] = "Enter valid format";

          isValid = false;
        } else {
          newErrors[key] = "";
        }
        console.log(isValid, "isValid121");
      }
      if (formData.age && isNaN(formData.age)) {
        newErrors[key] = "Age must be a valid number.";
        isValid = false;
      }
      if (formData.age) {
        const age = Number(formData.age);
        if (isNaN(age) || age < 0 || age > 100) {
          newErrors["age"] = "Age cannot be greater then 100.";
          isValid = false;
        }
      }
      if (key === "nationality" && !regexText.test(formData.nationality)) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }

      if (formData.email && !emailPattern.test(formData.email)) {
        newErrors["email"] = "Please enter valid email";
        isValid = false;
      }

      if (key === "contactNumber" && !regex.test(formData.contactNumber)) {
        newErrors[key] = "Please enter valid mobile number.";
        isValid = false;
      }
      if (key === "whatsappNumber" && !regex.test(formData.whatsappNumber)) {
        newErrors[key] = "Please enter valid mobile number.";
        isValid = false;
      }

      if (key === "nomineeName" && !regexText.test(formData.nomineeName)) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }
      if (key === "relationship" && !regexText.test(formData.relationship)) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }

      if (
        formData.nomineeContactNumber &&
        !regex.test(formData.nomineeContactNumber)
      ) {
        newErrors["nomineeContactNumber"] = "Please enter valid mobile number.";
        isValid = false;
      }
      if (!formData.paymentDate) {
        newErrors["paymentDate"] = "This field is required.";
        isValid = false;
      } else {
        if (!isValidDate(formData.paymentDate)) {
          newErrors["paymentDate"] =
            "Please enter a valid date in YYYY-MM-DD format";
          isValid = false;
        }
      }

      if (formData.paymentScreenshot) {
        if (!formData.paymentScreenshot.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          newErrors["paymentScreenshot"] = "Please enter a valid image format";
          isValid = false;
        }
      } else {
        // Clear the error if no file is selected
        delete newErrors["paymentScreenshot"];
      }
    }
    setErrors(newErrors);

    if (isValid) {
      const {
        firstApplicant,
        additionalText,
        parentHusband,
        swdOf,

        combinedValue,
        age,
        nationality,
        address,
        email,
        contactNumber,
        whatsappNumber,
        nomineeName,
        relationship,
        nomineeContactNumber,
        paymentDetails,
        paymentDate,
        utrNumber,
        paymentScreenshot,

        paidAmount,
      } = formData;
      try {
        const formDatas = new FormData();

        formDatas.append("name", `${additionalText} ${firstApplicant}`);

        formDatas.append(
          "parent_or_husband_name",
          `${parentHusband}/${swdOf} ${combinedValue}`
        );
        formDatas.append("age", age);
        formDatas.append("nationality", nationality);
        formDatas.append("address", address);
        formDatas.append("email", email);
        formDatas.append("contact_number", contactNumber);
        formDatas.append("whatsapp_number", whatsappNumber);
        formDatas.append("nominee_name", nomineeName);
        formDatas.append("relationship", relationship);
        formDatas.append("nominee_contact_number", nomineeContactNumber);
        // formDatas.append("signature", signature);
        formDatas.append("payment_details", paymentDetails);
        formDatas.append("payment_date", paymentDate);
        formDatas.append("utr_number", utrNumber);
        formDatas.append("paymentScreenshot", paymentScreenshot);
        formDatas.append("paid_amount", paidAmount);
        formDatas.append("reffralCode", reffralCode);

        const res = await register(formDatas);

        if (res.status) {
          toast.dismiss();
          setFormData(initialValue);
          setErrors({});
          setReffralCode("");
          toast.success(
            "Thank you for showing interest in Satyam City.Your Provisional Registration form is submitted."
          );
        } else {
          toast.dismiss();
          toast.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // =========== for date ===============//

  // Create a function to validate the date
  function isValidDate(dateString) {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    if (!datePattern.test(dateString)) {
      return false;
    }

    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    if (
      year < 1000 ||
      year > 9999 ||
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31
    ) {
      return false;
    }

    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getFullYear() === year &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getDate() === day
    );
  }

  const dateHandle = (e) => {
    const name = e.target.name;
    const dateValue = e.target.value;

    if (name === "paymentDate") {
      const isValid = isValidDate(dateValue);

      if (!isValid) {
        setErrors({
          ...errors,
          [name]: "Please enter a valid date in YYYY-MM-DD format",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "", // Clear the error if the date is valid
        });
        setFormData({
          ...formData,
          [name]: dateValue,
        });
      }
    }
  };

  // ================ for date ==========//

  const validNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const spaceCheck = (e) => {
    if (/^\s/.test(e.key)) {
      e.preventDefault();
    }
  };

  // const copyContactNumberToWhatsapp = (e) => {
  //   const { name } = e.target;
  //   console.log(name,"nnnnnn")

  //   let error = "";
  //   setFormData({
  //     ...formData,
  //     whatsappNumber: formData.contactNumber,
  //   });

  //   setErrors({
  //     ...errors,
  //     whatsappNumber: "",
  //   });
  // };

  const copyContactNumberToWhatsapp = () => {
    const checkbox = document.getElementById("fillContactNumberCheckbox");

    if (checkbox.checked) {
      setFormData({
        ...formData,
        whatsappNumber: formData.contactNumber,
      });

      setErrors({
        ...errors,
        whatsappNumber: "",
      });
    } else {
      setFormData({
        ...formData,
        whatsappNumber: "",
      });

      setErrors({
        ...errors,
        whatsappNumber: "This field is required",
      });
    }
  };

  console.log(formData.whatsappNumber, "444");
  const handleChangeForWhatsup = (e) => {
    const { name, value } = e.target;
    const regex = /^[6-9]\d{9}$/;
    let error = "";

    console.log(name, "name1111");
    if (name === "whatsappNumber" && !regex.test(value)) {
      error = "Invalid Indian phone number.";
    } else {
      error = "";
    }

    const newValue = {
      ...formData,
      [name]: value,
      whatsappNumber: value,
    };
    setFormData(newValue);
    setErrors({
      ...errors,
      [name]: error,
    });
  };
  const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const maxDate = tomorrow.toISOString().split('T')[0];
console.log(maxDate,"maxDate")
const minDate = '2023-08-08'; 

  return (
    <>
      <section className="in_page">
        <div className="container">
          <h6 className="hadding text-center">Provisional Registration Form</h6>
          <div className="launchpad_box">
            <Form>
              <div className="row">
                {/* <Form.Group className="col-md-6 col-12">
                  <div className="form-group">
                    <Form.Label>Name</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Mr"
                        name="firstApplicant"
                        value="Mr"
                        checked={formData.firstApplicant === "Mr"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Mrs"
                        name="firstApplicant"
                        value="Mrs"
                        checked={formData.firstApplicant === "Mrs"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Ms"
                        name="firstApplicant"
                        value="Ms"
                        checked={formData.firstApplicant === "Ms"}
                        onChange={handleChange}
                      />
                    </div>

                    <Form.Control
                      type="text"
                      name="firstApplicant"
                      value={
                        formData.firstApplicant.length === 1 ||
                        formData.firstApplicant.length === 2 ||
                        formData.firstApplicant.length === 3
                          ? `${formData.firstApplicant} `
                          : formData.firstApplicant
                      }
                      onChange={handleChange}
                      disabled={isNameEnabled}
                    />
                    {errors.firstApplicant && (
                      <span style={{ color: "red" }}>
                        {errors.firstApplicant}
                      </span>
                    )}
                  </div>
                </Form.Group> */}
                <Form.Group className="col-md-6 col-12">
                  <div className="form-group">
                    <Form.Label>Name</Form.Label>
                    <div>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Mr"
                          name="additionalText"
                          value="Mr"
                          checked={formData.additionalText === "Mr"}
                          onChange={handleChangeRadio}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="Mrs"
                          name="additionalText"
                          value="Mrs"
                          checked={formData.additionalText === "Mrs"}
                          onChange={handleChangeRadio}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="Ms"
                          name="additionalText"
                          value="Ms"
                          checked={formData.additionalText === "Ms"}
                          onChange={handleChangeRadio}
                        />
                      </div>
                    </div>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        {formData.additionalText}
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="firstApplicant"
                        value={formData.firstApplicant}
                        onChange={handleChange}
                        // onKeyPress={spaceCheck}
                      />
                    </InputGroup>
                    {errors.firstApplicant && (
                      <span style={{ color: "red" }}>
                        {errors.firstApplicant}
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="col-md-6 col-12">
                  <div className="form-group">
                    <Form.Label>S/W/D of Mr/Mrs/Ms</Form.Label>

                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="S"
                        name="parentHusband"
                        value="S"
                        checked={formData.parentHusband === "S"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="W"
                        name="parentHusband"
                        value="W"
                        checked={formData.parentHusband === "W"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="D"
                        name="parentHusband"
                        value="D"
                        checked={formData.parentHusband === "D"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Mr"
                        className="more-space"
                        name="swdOf"
                        value="Mr"
                        checked={formData.swdOf === "Mr"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Mrs"
                        name="swdOf"
                        value="Mrs"
                        checked={formData.swdOf === "Mrs"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Ms"
                        name="swdOf"
                        value="Ms"
                        checked={formData.swdOf === "Ms"}
                        onChange={handleChange}
                      />
                    </div>

                    {/* <Form.Control
                      type="text"
                      name="combinedValue"
                      value={
                        formData.combinedValue.length === 5 ||
                        formData.combinedValue.length === 6 ||
                        formData.combinedValue.length === 7
                          ? `${formData.combinedValue} `
                          : formData.combinedValue
                      }
                      //  value= {formData.combinedValue}
                      onChange={handleChangeForCombine}
                      disabled={isInputEnabled}
                    />
                    {errors.combinedValue ||
                      (error && (
                        <span style={{ color: "red" }}>
                          {errors.combinedValue || error}
                        </span>
                      ))} */}
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        {`${formData.parentHusband}/${formData.swdOf}`}
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="combinedValue"
                        value={formData.combinedValue}
                        // onKeyPress={spaceCheck}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    {errors.combinedValue && (
                      <span style={{ color: "red" }}>
                        {errors.combinedValue}
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="age"
                  className="col-md-4 col-sm-6 col-12"
                >
                  <div className="form-group">
                    <Form.Label>Age</Form.Label>
                    <Form.Control
                      type="text"
                      name="age"
                      value={formData.age}
                      onKeyPress={validNumber}
                      onChange={handleChange}
                    />
                    {errors.age && (
                      <span style={{ color: "red" }}>{errors.age}</span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="nationality"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Nationality</Form.Label>
                    <Form.Control
                      type="text"
                      name="nationality"
                      value={formData.nationality}
                      onKeyPress={spaceCheck}
                      onChange={handleChange}
                    />
                    {errors.nationality && (
                      <span style={{ color: "red" }}>{errors.nationality}</span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group controlId="address" className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formData.address}
                      // onKeyPress={spaceCheck}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <span style={{ color: "red" }}>{errors.address}</span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group controlId="email" className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      onKeyPress={spaceCheck}
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="contactNumber"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Contact Number</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                      <Form.Control
                        type="tel"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onKeyPress={validNumber}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    {errors.contactNumber && (
                      <span style={{ color: "red" }}>
                        {errors.contactNumber}
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="whatsappNumber"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Whatsapp Number</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                      <Form.Control
                        type="tel"
                        name="whatsappNumber"
                        value={formData.whatsappNumber}
                        onKeyPress={validNumber}
                        onChange={handleChangeForWhatsup}
                      />
                    </InputGroup>

                    <div className="form-group form-check m-0">
                      <span onClick={copyContactNumberToWhatsapp}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="fillContactNumberCheckbox"
                        />
                      </span>
                      <label
                        className="form-check-label"
                        htmlFor="fillContactNumberCheckbox"
                        // onClick={copyContactNumberToWhatsapp}
                        style={{ cursor: "pointer" }}
                      >
                        Fill in Contact Number
                      </label>
                    </div>

                    {errors.whatsappNumber && (
                      <span style={{ color: "red" }}>
                        {errors.whatsappNumber}
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="nomineeName"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Nominee's Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="nomineeName"
                      value={formData.nomineeName}
                      // onKeyPress={spaceCheck}
                      onChange={handleChange}
                    />
                    {errors.nomineeName && (
                      <span style={{ color: "red" }}>{errors.nomineeName}</span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="relationship"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="relationship"
                      value={formData.relationship}
                      // onKeyPress={spaceCheck}
                      onChange={handleChange}
                    />
                    {errors.relationship && (
                      <span style={{ color: "red" }}>
                        {errors.relationship}
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="nomineeContactNumber"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Nominee's Contact Number</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                      <Form.Control
                        type="tel"
                        name="nomineeContactNumber"
                        value={formData.nomineeContactNumber}
                        onKeyPress={validNumber}
                        onChange={handleChange}
                      />
                    </InputGroup>

                    {errors.nomineeContactNumber && (
                      <span style={{ color: "red" }}>
                        {errors.nomineeContactNumber}
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="paymentDetails"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Payment details</Form.Label>
                    <Form.Control
                      type="text"
                      name="paymentDetails"
                      value={formData.paymentDetails}
                      // onKeyPress={spaceCheck}
                      onChange={handleChange}
                    />
                    {errors.paymentDetails && (
                      <span style={{ color: "red" }}>
                        {errors.paymentDetails}
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="paymentDate"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Payment Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="paymentDate"
                      onKeyDown={(e) => e.preventDefault()}
                      value={formData.paymentDate}
                      // min={minDate}
                      // max="2023-10-27"
                      onChange={dateHandle}
                    />
                    {errors.paymentDate && (
                      <span style={{ color: "red" }}>{errors.paymentDate}</span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group controlId="utrNumber" className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <Form.Label>UTR Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="utrNumber"
                      value={formData.utrNumber}
                      onKeyPress={spaceCheck}
                      onChange={handleChange}
                    />
                    {errors.utrNumber && (
                      <span style={{ color: "red" }}>{errors.utrNumber}</span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="paymentScreenshot"
                  className="col-md-4 col-sm-6"
                >
                  <div className="form-group">
                    <Form.Label>Payment Screenshot</Form.Label>
                    <Form.Control
                      type="file"
                      name="paymentScreenshot"
                      onChange={handleChange}
                    />
                    {errors.paymentScreenshot && (
                      <span style={{ color: "red" }}>
                        {errors.paymentScreenshot}
                      </span>
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  controlId="paidAmount"
                  className="col-md-4 col-sm-6"
                >
                  {" "}
                  <div className="form-group">
                    <Form.Label>Paid Amount</Form.Label>
                    <Form.Control
                      type="number"
                      name="paidAmount"
                      value={formData.paidAmount}
                      onChange={handleChange}
                      onKeyPress={validNumber}
                    />
                    {errors.paidAmount && (
                      <span style={{ color: "red" }}>{errors.paidAmount}</span>
                    )}{" "}
                  </div>
                </Form.Group>
                <Form.Group controlId="sponserId" className="col-md-4 col-sm-6">
                  {" "}
                  <div className="form-group">
                    <Form.Label>Sponser Id</Form.Label>
                    <Form.Control
                      type="text"
                      name="sponserId"
                      value={reffralCode}
                      onKeyPress={spaceCheck}
                      onChange={(e) => setReffralCode(e.target.value)}
                    />
                    {/* {reffralCodeErr && (
                      <span style={{ color: "red" }}>{reffralCodeErr}</span>
                    )}{" "} */}
                  </div>
                </Form.Group>
              </div>
            </Form>
            <button className="btn btn-info mt-2" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </section>
      {/* {showSuccess && (
        <div className="full-page-overlay">
          <div className="full-page-content">
            <img src={require("../img/thanks.jpg")} alt="Success" />
          </div>
        </div>
      )} */}
    </>
  );
};

export default Register;
