import * as opsService from "../Ops";

import { registeration } from "../../Constant/Api";

const register = async (data) => {
  let result = await opsService.postdata(registeration, data);

  return result;
};

export { register };
