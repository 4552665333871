import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/HomePage/Home";
// import Launchpad from "./components/LaunchPad/Launchpad";
// import ProjectDetails from "./components/LaunchPad/ProjectDetails";
// import StakingFarming from "./components/Staking-Farming/StakingFarming";
// import Claim from "./components/Claim/Claim";
// import Profile from "./components/Profile/Profile";
// import TierSystem from "./components/TierSystem/TierSystem";
import Register from "./components/ContactUs/Register";
// import Calendars from "./components/Calendar/Calendars";
// import ApplyIdo from "./components/ApplyIdo/ApplyIdo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import  CustomCalendar  from './components/Mycalender';

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Register />} />
          <Route path="/:id" element={<Register />} />
          {/* <Route path="/contact-us" element={<Register />} /> */}
          {/* <Route path="/launchpad" element={<Launchpad />} />
          <Route path="/project-details/:id" element={<ProjectDetails />} />
          <Route path="/staking-farming" element={<StakingFarming />} /> */}
          {/* <Route path="/claim" element={<Claim />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/tier-system" element={<TierSystem />} />
          
          <Route path="/calendar" element={<Calendars />} />
          <Route path="/apply-ido" element={<ApplyIdo />} /> */}
          {/* <Route path='/Mycalender' element={<CustomCalendar/>} /> */}
        </Routes>
      </Router>
      <ToastContainer
      
        limit={1}
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
};

export default App;
