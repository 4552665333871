import { baseUrl } from "./BaseUrl";

export const registeration = baseUrl + "/registry";











